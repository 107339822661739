import styled from 'styled-components';
import imgTick from '../images/ico-tick-orange.svg';
import { themeGet } from '@styled-system/theme-get';

export default styled.ul`
  padding: 0;

  li {
    list-style-image: url(${imgTick});
    list-style-position: inside;
    font-weight: bold;
    font-size: ${themeGet('fontSizes.body')};
    line-height: ${themeGet('lineHeights.body')};
  }
`;
