import React from 'react';
import { Typography as T, Box, Flex, Grid } from '@fuji-ui/core';
import SEO from '../components/seo';
import { Wrapper } from '../components/layout';
import U from '../components/underline';
import Checklist from '../components/checklist';
import { FeatureBlock, WaveBlock, WaveStartBlock } from '../components/block';
import { FeatureCard } from '../components/cards';
// import ImgCrossplatform from '../images/img_crossplatform.png';
import ImgIntegrated from '../images/img_integrated.png';
import IconEvent from '../images/ico_event.svg';
import IconNews from '../images/ico_news.svg';
import IconBanner from '../images/ico_banner.svg';
import IconAcq from '../images/ico_acquaintance.svg';
import IconCustomize from '../images/ico_customize.svg';
import IconLinks from '../images/ico_links.svg';
// import ImgAppHome from '../images/teamwork-app-home.png';
// import ImgForm from '../images/img_form.png';
// import ImgWorkflow from '../images/img_workflow@2x.png';
// import ImgChatbot from '../images/img_chatbot.png';
// import ImgDrive from '../images/teamwork-drive.png';
// import GatsbyImage from '../components/image';
import { StaticImage } from 'gatsby-plugin-image';
const renderHomeFeatures = () => {
  const features = [
    {
      img: IconNews,
      name: 'News & Articles',
      desc: 'Feed-in company news and let everyone cheers for team success!',
    },
    {
      img: IconBanner,
      name: 'Banners',
      desc:
        'Slide-showing latest company promotion or important announcement. Link to an article, or website or even facebook pages.',
    },
    {
      img: IconEvent,
      name: 'Events',
      desc:
        'Track and remind team sharing or seminars sessions. Recruit participants and let others to know who is attending the event.',
    },
    {
      img: IconAcq,
      name: 'Acquaintance',
      desc:
        'Know and meet new joiners, or list all birthday stars this week. Let your team building the spirit.',
    },
    {
      img: IconLinks,
      name: 'Links',
      desc:
        'Links to useful resources, platforms or tools. Seamlessly bridging all your existing online resources.',
    },
    {
      img: IconCustomize,
      name: 'Fully Customizable',
      desc:
        "Couldn't find a suitable widget? You can always build your own one.",
    },
  ];
  return features.map(({ img, name, desc }) => (
    <FeatureCard key={name} textAlign="center" p="s">
      <img src={img} height="32px" />
      <Box mt="s" textAlign="center">
        <T.H4>{name}</T.H4>
        <T.P>{desc}</T.P>
      </Box>
    </FeatureCard>
  ));
};
const FeaturesPage = (props) => {
  return (
    <>
      <SEO
        lang="en"
        title="Features of Teamwork App and why you should use it"
      />
      <Box py="l" overflow="hidden">
        <Wrapper>
          <Flex
            alignItems={['stretch', null, 'center', null]}
            flexDirection={['column', null, 'row-reverse', null]}
          >
            <Box mr="-240px" flex={4} textAlign="center">
              {/* <img src={ImgMockup} width="100%" /> */}
              {/* <GatsbyImage /> */}
              {/* <ImgMockup /> */}
              <StaticImage
                placeholder="none"
                src="../images/mockup-app-1@2x.png"
                alt="Work starts from Conversations."
                imgStyle={{ width: '100%' }}
              />
            </Box>
            <Box flex={2}>
              <T.H1>Work starts from Conversations.</T.H1>
              <T.P>
                Email is never the best tool for internal communication.
                That&apos;s our motivation to develop a perfect tool to make our
                work life easier.
              </T.P>
              <T.H4>Group Chats</T.H4>
              <T.P>
                Chat rooms for team communication. Discussion, file sharing,
                group tasks all in one place.
              </T.P>
              <T.H4>Direct Message</T.H4>
              <T.P>
                Send direct message to anyone privately - with text, voice,
                media, file, location, and more!
              </T.P>
              <T.H4>Broadcast & Questionnaire</T.H4>
              <T.P>
                Make announcement and promotions for dedicated teams or members.
                Setup questionnaires and get instant response from your
                audience.
              </T.P>
            </Box>
          </Flex>
        </Wrapper>
      </Box>
      <WaveStartBlock pt="80px" pb="10px">
        <Box mt="l">
          <Wrapper>
            <FeatureBlock flexDirection="column">
              <T.H1>Deploying Company Whiteboard at everyone's pocket</T.H1>
              <T.P>
                News, useful resources, events, promotions - for all of these
                you can easily organize and put into a beautifully designed Home
                Screen, assembled using our widget system.
              </T.P>
              <Flex
                alignItems={['stretch', null, 'center', null]}
                flexDirection={['column', null, 'row', null]}
                mt="l"
              >
                <Box flex={2} ml={[null, '-60px', null, null]}>
                  {/* <img src={ImgAppHome} width="100%" /> */}
                  <StaticImage
                    src="../images/teamwork-app-home.png"
                    alt="Work starts from Conversations."
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
                <Grid gridTemplateColumns="auto auto" flex={3}>
                  {renderHomeFeatures()}
                </Grid>
              </Flex>
            </FeatureBlock>
          </Wrapper>
        </Box>
        <Box mt="l">
          <Wrapper>
            <FeatureBlock flexDirection="column">
              <T.H1>The cloud drive for the team</T.H1>
              <Flex
                mt="l"
                alignItems={['stretch', null, 'center', null]}
                flexDirection={['column', null, 'row-reverse', null]}
              >
                <Box flex={2} p="m">
                  {/* <img src={ImgDrive} width="100%" /> */}
                  <StaticImage
                    src="../images/teamwork-drive.png"
                    alt="The cloud drive for the team."
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
                <Box flex={2}>
                  <T.H4>A zero-config sharable drive for the team</T.H4>
                  <T.P>
                    Teammates can securely store, search and share files without
                    relying on third-party storage service. Files are organized
                    into folders, from where owners can set who's permitted to
                    view or edit those files.
                  </T.P>
                  <T.H4>Control how files are being shared</T.H4>
                  <T.P>
                    Teamwork Drive is the dataroom of your organization. File is
                    maintained from a single data source - even someone has
                    shared the file to the others, you may revert and keep your
                    file closed anytime.
                  </T.P>
                </Box>
              </Flex>
            </FeatureBlock>
          </Wrapper>
        </Box>
      </WaveStartBlock>
      <WaveBlock
        blueStart
        waveBgColor="#37474f"
        color="white"
        py="100px"
        mt="-2px"
      >
        <Box mt="l">
          <Wrapper>
            <FeatureBlock flexDirection="column">
              <T.H1>Making the organization workflow even better</T.H1>
              <T.P>
                Standardizes business processes by adopting automation with the
                help of our workflow system. You can save thousands of human
                hours and make tasks completed faster at the same time.
              </T.P>
              <Flex
                alignItems={['stretch', null, 'center', null]}
                flexDirection={['column', null, 'row', null]}
                my="l"
              >
                <Box flex={1}>
                  {/* <img src={ImgForm} width="100%" /> */}
                  <StaticImage
                    src="../images/img_form.png"
                    alt="Making the organization workflow even better."
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
                <Box flex={1} m="l">
                  <T.H2>
                    <U>Form</U>
                  </T.H2>
                  <T.P>
                    Create and publish form to collect information for
                    organization. Manage application forms, collect feedback on
                    satisfaction, or do a quick polling.
                  </T.P>
                  <Checklist mt="l">
                    <li>Single/Multiple Choices</li>
                    <li>Text</li>
                    <li>Number</li>
                    <li>Checkbox</li>
                    <li>Rating</li>
                    <li>Date & Time</li>
                    <li>Photo Upload</li>
                  </Checklist>
                </Box>
              </Flex>
              <Flex
                alignItems={['stretch', null, 'center', null]}
                flexDirection={['column', null, 'row-reverse', null]}
              >
                <Box flex={1}>
                  {/* <img src={ImgWorkflow} width="100%" /> */}
                  <StaticImage
                    src="../images/img_workflow@2x.png"
                    alt="Making the organization workflow even better."
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
                <Box flex={1} m="l">
                  <T.H2>
                    <U>Workflow</U>
                  </T.H2>
                  <T.P>
                    Highly-customizable module let you build your own workflow
                    that suits your company the best. Connect workflow to
                    third-party tools or internal systems to automate work
                    tasks.
                  </T.P>
                  <Box>
                    <Checklist mt="m">
                      <li>Come with 25+ predefined functions</li>
                      <li>Powered by customizable scripts</li>
                      <li>Could be integrated with internal systems</li>
                    </Checklist>
                  </Box>
                </Box>
              </Flex>
              <Flex
                alignItems={['stretch', null, 'center', null]}
                flexDirection={['column', null, 'row', null]}
              >
                <Box flex={1}>
                  {/* <img src={ImgChatbot} width="100%" /> */}
                  <StaticImage
                    src="../images/img_chatbot.png"
                    alt="Workflow."
                    imgStyle={{ width: '100%' }}
                  />
                </Box>
                <Box flex={1} mt="l">
                  <T.H2>
                    <U>Chatbot Framework</U>
                  </T.H2>
                  <T.P>
                    Chatbots are way too helpful. We like them and are using
                    them in our team. That's why we've built a framework to help
                    organization to create and adopt their own chatbots.
                  </T.P>
                  <T.H4>System Friendly</T.H4>
                  <T.P>
                    Chatbot can be hosted anywhere, using any code base you
                    like.
                  </T.P>
                  <T.H4>Smart Conversation</T.H4>
                  <T.P>
                    Support chatbot menu and chat options to streamline chat
                    experience.
                  </T.P>
                  <T.H4>Connect Seamlessly</T.H4>
                  <T.P>
                    Chatbot knows to whom she is talking without asking by using
                    Teamwork API.
                  </T.P>
                </Box>
              </Flex>
            </FeatureBlock>
          </Wrapper>
        </Box>
      </WaveBlock>
      <Box>
        <Wrapper>
          <Flex
            alignItems="stretch"
            flexDirection={['column', null, 'row', null]}
          >
            <Box
              flex={1}
              p="l"
              borderRight={[null, null, 'light', null]}
              mr="m"
            >
              <T.H2>Seamless cross-platform experience.</T.H2>
              <T.P>
                You can choose any platform, or use them all at once. All the
                conversations are synchronized automatically.
              </T.P>
              <Box mt="l" textAlign="center">
                {/* <img src={ImgCrossplatform} width="80%" /> */}
                <StaticImage
                  src="../images/img_crossplatform.png"
                  alt="Seamless cross-platform experience."
                  imgStyle={{ width: '80%', height: '80%' }}
                />
              </Box>
            </Box>
            <Box flex={1} p="l">
              <T.H2>Prepared to be integrated.</T.H2>
              <T.P>
                Access 300+ APIs to integrate our products with your
                organization system or third-party services.
              </T.P>
              <Box mt="l" textAlign="center">
                {/* <img src={ImgIntegrated} width="60%" /> */}
                <StaticImage
                  src="../images/img_integrated.png"
                  alt="Prepared to be integrated."
                  imgStyle={{ width: '60%', height: '60%' }}
                />
              </Box>
            </Box>
          </Flex>
        </Wrapper>
      </Box>
    </>
  );
};
export default FeaturesPage;
