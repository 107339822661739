import React from 'react';
import { Box } from '@fuji-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

// cloning the Card component from fuji
const Card = (props) => {
  const { header, contentSpace, shadow } = props;
  const r = 4;
  return (
    <Box
      borderRadius={r}
      boxShadow={shadow}
      display="inline-block"
      backgroundColor="surface"
      color="onSurface"
      {...props}
    >
      {header && (
        <Box borderTopLeftRadius={r} borderTopRightRadius={r} overflow="hidden">
          {header()}
        </Box>
      )}
      {props.children && (
        <Box p={contentSpace} height="100%">
          {props.children}
        </Box>
      )}
    </Box>
  );
};

Card.displayName = 'Card';

Card.propTypes = {
  ...Box.propTypes,
  header: PropTypes.func,
  contentSpace: PropTypes.oneOfType([
    PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
    PropTypes.number,
  ]),
  shadow: PropTypes.string,
};

Card.defaultProps = {
  contentSpace: 'm',
  shadow: 'large',
};

export const FeatureCard = styled(Card)`
  margin: ${themeGet('space.s')};
  box-shadow: ${themeGet('shadows.small')};
  flex: 1;
  p {
    font-size: 1rem;
    line-height: 1.4;
  }
  h4 {
    font-size: 1.1rem;
  }
`;
