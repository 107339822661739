import React from 'react';
import Proptypes from 'prop-types';
import styled from 'styled-components';
import Stroke from '../images/brush-stroke.svg';

const U = styled.span`
  display: block;
  position: relative;
  > span {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    > span {
      padding-bottom: 0.5rem;
      background: url(${Stroke}) right bottom 0rem / 105% 1rem no-repeat;
      color: transparent;
    }
  }
`;

const Underline = (props) => {
  return (
    <U {...props}>
      {props.children}
      <span aria-hidden="true">
        <span>{props.children}</span>
      </span>
    </U>
  );
};

Underline.propTypes = {
  color: Proptypes.string,
};

export default Underline;
